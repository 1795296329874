import React from 'react';

// External Components
import { Section } from '@thepuzzlers/pieces';

// Local Components
import { BigDescriptionBlock, SpringLineTwo } from 'components';

export const About = ({ data: { description } }) => {
  return (
    <Section
      id="management-and-investors__about"
      sx={{
        position: 'relative'
      }}>
      <BigDescriptionBlock
        description={description}
        sx={{
          gridColumn: [
            '1/13',
            null,
            '3/ span 20',
            null,
            '5/ span 16',
            '5/ span 16'
          ]
        }}
      />
      <Decoration />
    </Section>
  );
};

const Decoration = () => {
  return (
    <SpringLineTwo
      sx={{
        position: 'absolute',
        width: ['11.2rem', null, '13.8rem', null, '16rem', '20rem'],
        gridColumnEnd: [13, null, 25, null, 24, 24],
        justifySelf: 'end',
        bottom: ['-15.9rem', null, '-8.6rem', null, '-10.4rem', '-14.8rem']
      }}
    />
  );
};
