import React from 'react';

// External Components
import { Spacer, SpringLine, StretchedSpringLine } from 'components';
import { InvestorItemSection } from './components';

export const InvestorList = ({ data: { list } }) => {
  return (
    <>
      {list.map((data, index) => (
        <React.Fragment key={index}>
          {index !== 0 && <ItemSpacer />}
          <InvestorItemSection key={index} data={data} id={`investor-${index}`}>
            {index === 2 && <SecondInvestorDecoration />}
          </InvestorItemSection>
        </React.Fragment>
      ))}
    </>
  );
};

const ItemSpacer = () => {
  return <Spacer height={['18rem', null, '30rem', null, '25rem', '25rem']} />;
};

const SecondInvestorDecoration = () => {
  return (
    <SpringLine
      sx={{
        position: 'absolute',
        width: ['17.3rem', null, '19.9rem', null, '23.4rem', '23.4rem'],
        gridColumnEnd: [13, null, 25, null, 25, 25],
        justifySelf: 'end',
        top: ['-10rem', null, '-3.9rem', null, '-11.2rem', '-11.1rem'],
        right: ['-8.6rem', null, '-6.1rem', null, '-11.5rem', 0]
      }}
    />
  );
};
