import { graphql, useStaticQuery } from 'gatsby';

export const useManagementAndInvestorsData = () => {
  const data = useStaticQuery(graphql`
    query {
      managementAndInvestorsDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
      }
    }
  `);

  return data.managementAndInvestorsDataJson;
};
