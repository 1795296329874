import React from 'react';

// External Components
import { Section, Heading, Paragraph, Box } from '@thepuzzlers/pieces';
import { convertApiHtmlText } from 'graphqlHooks/helper';
import { AspectRatioImageContainer } from 'components';

export const InvestorItemSection = ({
  data: { name, position, description, photo },
  children = null,
  PhotoElement,
  ...props
}) => {
  return (
    <Section
      id="management-and-investors__list"
      sx={{
        position: 'relative'
      }}
      {...props}>
      <NamePositionAndPhoto
        name={name}
        position={position}
        photo={photo}
        PhotoElement={PhotoElement}
      />
      <DescriptionBlock description={description} />
      {/* children mean to be used to put in the section decoration */}
      {children}
    </Section>
  );
};

const NamePositionAndPhoto = ({ name, position, photo }) => {
  return (
    <Box
      className="__name-and-position"
      sx={{
        gridColumn: [
          '1/13',
          null,
          '2/ span 20',
          null,
          '2/ span 10',
          '3/ span 10'
        ],
        position: 'relative',
        alignSelf: 'start'
      }}>
      <Photo photo={photo} />
      <Heading
        as="h2"
        sx={{
          fontFamily: 'primary.bold',
          lineHeight: 1.25,
          fontSize: ['4rem', null, '4.8rem', null, '5.6rem', '6.4rem'],
          mt: ['-3.7rem', null, '0.4rem', null, '0.4rem', '0.4rem']
        }}
        dangerouslySetInnerHTML={{ __html: convertApiHtmlText(name.html) }}
      />
      <Paragraph
        sx={{
          fontFamily: 'body.normal',
          lineHeight: 1,
          fontSize: ['1.6rem', null, '1.6rem', null, '1.6rem', '1.6rem'],
          mt: ['1.2rem', null, '0.8rem', null, '0.6rem', '0.6rem']
        }}>
        {position}
      </Paragraph>
    </Box>
  );
};

const Photo = ({ photo: { image, alt }, sx }) => {
  return (
    <AspectRatioImageContainer
      src={image}
      alt={alt}
      aspectRatios={1 / 1.4123}
      className="__photo"
      sx={{
        width: ['21.1rem', null, '19.8rem', null, '20.1rem', '25.4rem'],
        ml: ['auto', null, 'unset', null, 'unset', 'unset'],
        position: ['relative', null, 'absolute', null, 'absolute', 'absolute'],
        bottom: [null, null, '-1.3rem', null, '-23.9rem', '-11.9rem'],
        right: [null, null, '6rem', null, 0, 0],
        zIndex: -1,
        ...sx
      }}
    />
  );
};

const DescriptionBlock = ({ description }) => {
  return (
    <Paragraph
      sx={{
        gridColumn: [
          '1/13',
          null,
          '4/ span 18',
          null,
          '13/ span 10',
          '14/ span 9'
        ],
        fontFamily: 'body.normal',
        lineHeight: 1.5,
        fontSize: ['1.6rem', null, '1.7rem', null, '1.7rem', '1.7rem'],
        mt: ['3rem', null, '3rem', null, '7rem', '7rem'],
        '& span': {
          fontFamily: 'body.boldItalic'
        }
      }}
      dangerouslySetInnerHTML={{ __html: convertApiHtmlText(description.html) }}
    />
  );
};
