import React from 'react';

// External Components
import { Section, Box, Heading } from '@thepuzzlers/pieces';

// Local Components

export const Header = ({ data: { headline, title } }) => {
  return (
    <Section as="header" id="management-and-investors__header">
      <TitleAndHeadline title={title} headline={headline} />
    </Section>
  );
};

const TitleAndHeadline = ({ title, headline }) => {
  return (
    <Box
      className="__title-and-headline"
      sx={{
        gridColumn: [
          '1/13',
          null,
          '1/ span 22',
          null,
          '2/ span 18',
          '2/ span 15'
        ],
        mt: ['12.3rem', null, '14.3rem', null, '14.3rem', '18.6rem']
      }}>
      <Heading
        className="__title"
        as="h1"
        sx={{
          fontFamily: 'body.normal',
          textTransform: 'uppercase',
          letterSpacing: '0.05em',
          fontSize: ['1.4rem', null, '1.6rem', null, '1.8rem', '1.8rem'],
          lineHeight: 1
        }}>
        {title}
      </Heading>
      <Heading
        as="h2"
        className="__headline"
        sx={{
          fontFamily: 'primary.bold',
          lineHeight: 1.25,
          fontSize: ['4rem', null, '5.6rem', null, '7.2rem', '7.2rem'],
          mt: '2rem'
        }}>
        {headline}
      </Heading>
    </Box>
  );
};
