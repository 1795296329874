import React from 'react';
import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';
import { Helmet } from 'react-helmet';
import { Box } from '@thepuzzlers/pieces';

// Local Components
import { Header, About, InvestorList } from 'sections/managementAndInvestors';
import { Spacer, Suitability } from 'components';

// Assets
import thumbnail from 'assets/seo/thumbnail.png';

// Hooks
import { useKisManagementAndInvestorsPageData } from 'graphqlHooks';
import { useManagementAndInvestorsData } from 'sections/managementAndInvestors';

const ValuesAndMission = () => {
  const { seo } = useManagementAndInvestorsData();

  const {
    header: cmsHeader,
    about: cmsAbout,
    investor_list: cmsInvestorList
  } = useKisManagementAndInvestorsPageData();

  return (
    <>
      <SEO {...seo} />
      <Helmet>
        {/* Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seo.url} />
        <meta property="og:image" content={thumbnail} />

        {/* Twitter */}
        <meta property="twitter:url" content={seo.url} />
        <meta property="twitter:image" content={thumbnail} />
      </Helmet>
      <Header data={cmsHeader} />
      <Spacer height={['6.6rem', null, '7rem', null, '9rem', '8.7rem']} />
      <Box
        as="main"
        sx={{
          overflow: 'hidden'
        }}>
        <About data={cmsAbout} />
        <Spacer height={['17.4rem', null, '24rem', null, '26rem', '40rem']} />
        <InvestorList data={cmsInvestorList} />
        <Spacer
          height={['24.4rem', null, '26.8rem', null, '25.2rem', '29.2rem']}
        />
        <Suitability />
        <Spacer height={['20rem', null, '28rem', null, '26rem', '30rem']} />
      </Box>
    </>
  );
};

export default injectIntl(ValuesAndMission);
